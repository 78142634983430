/* @import "tailwindcss/base"; */
@import "tailwindcss/components";
/* @import "tailwindcss/utilities"; */

strong{
  @apply font-medium;
}
.material-icons{
}
.c--layout-navbar{
  &__link{
    @apply mr-8;
  }
  &__link:hover{
    color: var(--theme-secondary-color);
  }
  &__link--selected{
    @apply border-b-2;
    color: var(--theme-secondary-color);
    border-color: var(--theme-secondary-color);
  }
  &__links a:last-child{
    @apply mr-0;
  }
}

.link, .link span{
  @apply transition-default;
  color: var(--theme-main-color);
  border-color: var(--theme-main-color);
  &:hover {
    color: var(--theme-secondary-color);
    border-color: var(--theme-secondary-color);
  }

  &--underlined{ @apply border-b-2 }
  &--gray{
    @apply text-gray-400 border-gray-300;
    &:hover{
      color: var(--theme-main-color);
      border-color: var(--theme-main-color);
    }
  }
  &--selected{ 
    @apply border-b-2 pb-0.5;
    color: var(--theme-secondary-color);
    border-color: var(--theme-secondary-color);
  }
}
.btn{
  @apply border-2 border-transparent py-3 px-5 cursor-pointer bg-gray-400 rounded transition-default;

  &.btn--primary { 
    @apply text-white;
    background-color: var(--theme-main-color);
  }
  &.btn--primary.btn--outline { 
    @apply border-2 bg-transparent;
    border-color: var(--theme-main-color);
    color: var(--theme-main-color);
  }

  &.btn--secondary{ @apply bg-theme-teal-2 text-white }
  &.btn--secondary.btn--outline{ @apply border-2 border-theme-teal-2 bg-transparent text-theme-teal-2 }

  &.btn--danger{ @apply bg-red-500 text-white }
  &.btn--danger.btn--outline{ @apply bg-white border-2 border-red-500 text-red-500 }

  &:hover{ @apply opacity-75 }
  &.btn--disabled, &.btn--disabled:hover{ @apply bg-gray-100 text-gray-400 cursor-not-allowed }

  &.rounded--full{ @apply rounded-full }

  &.btn--sm.rounded--full{ @apply !px-2 !py-0.5 }
  &.btn--sm.rounded--full.btn--outline:hover{ 
    @apply text-white;
    background-color: var(--theme-secondary-color);
    border-color: var(--theme-secondary-color);
  }
}
a.btn{
  @apply py-3
}
.box.box-1{
  img{ @apply m-0 }
  p{ @apply m-0 }
}
#welcome-faq h2:hover{
  color: var(--theme-main-color);
}
.certificate-header{
  background-color: var(--theme-main-color);
}
.bottom-7 {
  bottom: 28px;
}
[data-controller="app--survey-content-step--survey-content-step-component"] ul{
  @apply list-disc;
}
[data-controller="app--survey-content-step--survey-content-step-component"] ol{
  @apply list-decimal;
}